import { css } from '@emotion/css';
import { GrafanaTheme2, TimeRange } from '@grafana/data';
import {
  Cascader,
  HorizontalGroup,
  InlineField,
  InlineFieldRow,
  RefreshPicker,
  Select,
  TimeRangePicker,
  useStyles2,
} from '@grafana/ui';
import React from 'react';

import { useToolbar } from './domain/useToolbar';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    toolbar: css`
      margin-bottom: ${theme.spacing(1)};
    `,
  };
};

export type ToolbarProps = {
  isLoading: boolean;
  onRefresh: () => void;
  onChangeTimeRange: (newTimeRange: TimeRange) => void;
  onChangeService?: (newServiceId: string) => void;
  onChangeProfileType?: (newProfileMetricId: string) => void;
};

export function Toolbar(props: ToolbarProps) {
  const styles = useStyles2(getStyles);
  const { data, actions } = useToolbar(props);

  return (
    <div className={styles.toolbar} data-testid="toolbar">
      <HorizontalGroup justify="space-between" align="flex-start">
        <InlineFieldRow>
          <InlineField label="Service" data-testid="services-dropdown">
            <Cascader
              // without the key, the Cascader option is not selected when landing :man_shrug:
              key={data.selectedServiceId}
              aria-label="Services list"
              width={32}
              separator="/"
              displayAllSelectedLevels
              placeholder={data.servicePlaceHolder}
              options={data.serviceOptions}
              initialValue={data.selectedServiceId}
              changeOnSelect={false}
              onSelect={actions.selectService}
            />
          </InlineField>
          <InlineField label="Profile" data-testid="profile-types-dropdown">
            <Select<string>
              aria-label="Profiles list"
              placeholder={data.profilePlaceHolder}
              options={data.profileOptions}
              value={data.selectedProfileId}
              onChange={actions.selectProfile}
            />
          </InlineField>
        </InlineFieldRow>

        <HorizontalGroup align="flex-start">
          <TimeRangePicker
            isOnCanvas={true}
            onChange={actions.setTimeRange}
            onChangeTimeZone={actions.setTimeZone}
            value={data.timeRange}
            onZoom={actions.zoom}
            onMoveBackward={actions.moveTimeRangeBackward}
            onMoveForward={actions.moveTimeRangeForward}
          />

          <RefreshPicker
            isOnCanvas={true}
            noIntervalPicker={true}
            onRefresh={actions.refresh}
            onIntervalChanged={actions.setInterval}
            isLoading={data.isLoading}
            width="36px"
          />
        </HorizontalGroup>
      </HorizontalGroup>
    </div>
  );
}
